.promo-screen {
  position: relative;
  height: 720px;
  overflow: auto;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .promo-header {
    font-size: 76px;
    line-height: 80px;
    font-family: "Manrope", sans-serif;
    font-weight: 800;
    margin: 130px 0 0 0;

    @include toDesktop {
      font-size: 36px;
      line-height: 44px;
    }

    @include mobile {
      margin: 80px 0 0 0;
    }

    span {
      white-space: nowrap;
      color: map-get($color, grey);
    }
  }

  .promo-text {
    margin-top: 30px;
    font-size: 24px;
    line-height: 36px;
    max-width: 680px;

    @include toDesktop {
      font-size: 16px;
      line-height: 24px;
      margin-top: 20px;
    }
  }

  .promo-controls {
    margin-top: auto;
    height: 128px;
    display: flex;
    align-items: center;

    @include mobile {
      margin: auto -16px 0 -16px;
      position: relative;
      height: 86px;
    }
  }

  .confirm-button {
    width: 228px;
    margin-right: 16px;

    @include mobile {
      display: none;
    }
  }

  .promo-scroll {
    width: 272px;
    height: 100%;
    padding-top: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 4px solid map-get($color, dark);
    font-size: 16px;
    line-height: 1;
    font-weight: bold;

    @include mobile {
      width: 160px;
    }

    &:hover {
      cursor: pointer;

      .svg-icon {
        @keyframes scroll-icon-animation {
          0% {
            transform: translateY(0);
          }
          10% {
            transform: translateY(10px);
          }
        }

        // transform: translateY(15px);
        animation: scroll-icon-animation;
        animation-duration: 0.7s;
        animation-fill-mode: backwards;
        animation-iteration-count: 2;
      }
    }

    .svg-icon {
      margin-left: 5px;
      height: 12px;
      width: auto;
      // transition: ease all 0.5s;
    }
  }

  .say-hello {
    margin-left: auto;
    font-size: 16px;
    font-weight: bold;

    @include toDesktop {
      position: absolute;
      right: 16px;
      bottom: 16px;
      background-color: map-get($color, green);
      border-radius: 50%;
      width: 60px;
      height: 60px;
      transition: ease all 0.25s;

      &:hover {
        cursor: pointer;
        background-color: darken(map-get($color, green), 3%);
      }

      &:active {
        background-color: darken(map-get($color, green), 5%);
      }
    }

    &:hover {
      .svg-icon {
        animation: tada;
        animation-duration: 1s;
      }
    }

    a {
      color: map-get($color, blue);
    }

    .desktop-content {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 4px;
      align-items: center;

      @include toDesktop {
        display: none;
      }
    }

    .mobile-content {
      display: inline-block;
      vertical-align: top;
      position: absolute;
      top: 18px;
      left: 16px;

      @include desktop {
        display: none;
      }
    }
  }
}
