.solutions {
  margin: 100px 0;

  @include mobile {
    margin: 60px 0;
  }

  .solutions-title {
    font-size: 16px;
    font-family: "Manrope", sans-serif;
    font-weight: bold;
    line-height: 1;
  }

  .solutions-text {
    font-size: 52px;
    font-family: "Manrope", sans-serif;
    font-weight: bold;
    line-height: 60px;
    margin-top: 50px;

    @include mobile {
      font-size: 28px;
      line-height: 36px;
    }

    .text-accent {
      color: map-get($color, green);
      color: green;
    }
  }

  .solutions-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 50px 80px;
    justify-items: center;
    margin-top: 80px;

    @include mobile {
      margin-top: 40px;
      grid-gap: 16px;
      grid-template-columns: repeat(auto-fill, minmax(136px, 1fr));
    }
  }

  .solution-item {
    width: 200px;
    height: 200px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile {
      width: 136px;
      height: 136px;
    }

    &:before {
      content: "";
      position: absolute;
      z-index: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      height: 90%;
      border-radius: 50%;
      border: 1px solid map-get($color, dark);
      transition: ease all 0.3s;

      @include mobile {
        width: 100%;
        height: 100%;
      }
    }

    &:hover,
    &.is-active {
      cursor: default;

      &:before {
        width: 130%;
        height: 130%;
        border: 1px solid map-get($color, green);

        @include mobile {
          width: 115%;
          height: 115%;
        }
      }

      .svg-icon {
        fill: map-get($color, green);
      }
    }

    .item-content {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-items: center;
      flex-direction: column;
    }

    .item-text {
      background-color: map-get($color, light);
      text-align: center;
      font-size: 24px;
      line-height: 32px;
      white-space: nowrap;

      @include mobile {
        font-size: 16px;
        line-height: 20px;
      }
    }

    .svg-icon {
      transition: ease fill 0.3s;
      margin-bottom: 10px;
    }
  }
}
