.expertise-item {
  padding: 24px;

  @include tablet {
    margin: 0 -24px;
  }

  @include tablet {
    transition: ease background-color 0.25s;
    border-radius: 6px;

    &:hover {
      background-color: white;

      .item-header {
        .item-line {
          background-color: map-get($color, green);
        }

        .svg-icon {
          fill: map-get($color, green);
        }
      }
    }

    &:not(:hover) {
      .item-header {
        .svg-icon {
          margin-left: 0;
          width: 0;
          opacity: 0;
          transition-delay: 0.25s, 0.25s, 0s, 0s;
        }
      }
    }
  }

  @include mobile {
    padding: 24px 0;
  }

  .item-header {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: stretch;
    position: relative;

    .item-line {
      height: 1px;
      width: 100%;
      background-color: map-get($color, dark);

      @include tablet {
        transition: ease background-color 0.25s;
      }
    }

    .svg-icon {
      flex: 0 0 auto;
      margin-left: 28px;
      transition: ease fill 0.25s;

      @include tablet {
        transition: ease width 0.25s, ease margin 0.25s, ease fill 0.25s,
          ease opacity 0.25s;
        transition-delay: 0s, 0s, 0.25s, 0.25s;
      }
    }
  }

  .item-title {
    font-size: 36px;
    font-weight: bold;
    line-height: 40px;

    @include mobile {
      font-size: 24px;
      line-height: 28px;
    }
  }

  .item-text {
    font-size: 24px;
    font-weight: normal;
    line-height: 36px;
    margin-top: 25px;

    @include mobile {
      font-size: 16px;
      line-height: 24px;
      margin-top: 15px;
    }
  }
}
