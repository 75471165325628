.footer-background {
  background: #1f2122;

  .video-element {
    @include mobile {
      max-height: 590px;
    }
  }
}

.contacts {
  position: relative;

  .contact-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 39px 50px;
    max-width: 700px;
    position: relative;

    @include mobile {
      grid-template-columns: 1fr;
      grid-gap: 43px 0;
    }

    .form-response {
      position: absolute;
      top: 0;
      width: 100%;
      max-width: 560px;

      ~ * {
        position: relative;
        left: -99999px;
      }
    }

    .is-textarea {
      @include tablet {
        grid-column: span 2;
      }
    }

    .form-title {
      font-size: 40px;
      font-family: "Manrope", sans-serif;
      font-weight: bold;
      line-height: 1;
      color: white;
      margin: 0;
      padding: 70px 0;

      @include tablet {
        grid-column: span 2;
      }

      @include mobile {
        padding: 60px 0;
      }
    }

    .confirm-button {
      justify-self: start;
      width: 272px;
    }
  }
}
