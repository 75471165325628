.input {
  display: grid;
  position: relative;
  min-height: 53px;
  align-items: end;

  &.is-active {
    .input-label {
      transform: translateY(0);
      color: map-get($color, grey);
      font-size: 10px;
    }
  }

  &.is-validating {
    input,
    textarea {
      &:invalid {
        border-color: map-get($color, red);
      }
    }
  }

  input,
  textarea {
    min-width: 0;
    outline: none;
    border: 0;
    background: transparent;
    color: white;
    height: 44px;
    font-size: 18px;
    border-bottom: 1px solid rgba(map-get($color, grey), 0.35);
  }

  textarea {
    resize: vertical;
    margin-top: 9px;
    padding-top: 11px;
    max-height: 200px;
  }

  .input-label {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(31px) translateY(-50%);
    font-size: 12px;
    white-space: nowrap;
    color: white;
    pointer-events: none;
    transition: ease color 0.25s, ease transform 0.25s, ease font-size 0.25s;
  }
}
