.header {
  width: 100%;
  height: 128px;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 9000;
  transition: ease background-color 0.3s, ease height 0.3s, ease box-shadow 0.3s;

  @include mobile {
    height: 88px;
    overflow: hidden;
    align-items: flex-start;
  }

  &.is-active {
    @include mobile {
      background-color: #ffffff;
      height: 100%;
    }
  }

  &.is-animating {
    @include mobile {
      background-color: #ffffff;
    }
  }

  &.is-floating {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    height: 60px;

    @include mobile {
      &.is-active {
        height: 100%;
      }

      .toggle-button {
        top: 12px;
      }

      .logo {
        margin: 16px 0 30px 0;
      }
    }
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mobile {
      height: 100vh;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  .logo {
    width: auto;
    height: 28px * 1.2;
    transition: ease margin 0.3s;

    @include mobile {
      margin: 30px 0;
    }
  }

  .nav {
    @include mobile {
      grid-auto-flow: row;
      grid-gap: 0;

      .nav-item {
        font-size: 36px;
        line-height: 40px;
        font-family: "Manrope", sans-serif;
        font-weight: 800;
      }
    }
  }

  .contacts-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    margin: auto 0 0 0;

    @include tablet {
      display: none;
    }
  }

  .address {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    text-align: center;
    background-color: map-get($color, light);
    border-radius: 30px;
    margin: 20px 0;

    .icon-location {
      fill: rgb(25, 27, 29);
    }

    @include tablet {
      display: none;
    }
  }

  .copyright {
    margin: 0 0 30px 0;

    @include tablet {
      display: none;
    }
  }

  .toggle-button {
    position: absolute;
    top: 27px;
    right: 16px;
    width: 36px;
    height: 36px;
    transition: ease top 0.3s;

    @include tablet {
      display: none;
    }

    &:hover {
      cursor: pointer;

      .line {
        background-color: map-get($color, green);
      }
    }

    .line {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transform-origin: 50% 50%;
      width: 100%;
      height: 2px;
      background-color: map-get($color, dark);
      transition: ease background-color 0.25s;

      &.line-1 {
        transform: translate(-50%, -50%) translateY(-3px);
      }

      &.line-2 {
        transform: translate(-50%, -50%) translateY(3px);
      }
    }
  }
}
