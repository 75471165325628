.contacts-list {
  .contact-item {
    .contact-label {
      font-size: 12px;
      line-height: 24px;
      color: map-get($color, grey);
    }

    .contact-value {
      font-size: 16px;
      font-weight: bold;
      text-decoration: none;
      color: map-get($color, dark);

      &[href^="mailto"] {
        color: map-get($color, blue);
      }
    }
  }
}
