.video-background {
  position: relative;

  .video-element {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    ~ * {
      position: relative;
    }
  }
}
