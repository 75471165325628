.retry-button {
  border: 2px solid #ffffff;
  background-color: transparent;
  border-radius: 30px;
  height: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  color: #ffffff;
  padding: 0 60px;
  outline: none;
  transition: ease border-color 0.25s;

  &:hover {
    cursor: pointer;
    border-color: map-get($color, green);
  }
}
