.footer {
  padding: 60px 0 50px 0;

  @include mobile {
    padding: 50px 0 50px 0;
  }

  .nav-container {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 0 32px;

    @include mobile {
      grid-template-columns: 1fr;
    }

    .logo {
      width: auto;
      height: 28px * 1.2;
    }

    .nav {
      @include mobile {
        display: none;
      }

      .nav-item {
        color: white;
      }
    }
  }

  .contacts-list {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 16px;

    @include mobile {
      grid-template-columns: 1fr;
      grid-gap: 35px;
    }

    .address {
      font-size: 12px;
      line-height: 20px;

      .address-line1 {
        color: white;

        .icon-location {
          fill: white;
        }
      }
      .address-line2 {
        color: map-get($color, grey);
      }
    }

    .contact-item {
      .contact-value {
        font-size: 24px;

        &[href^="tel"] {
          color: white;
        }
      }
    }

    .copyright {
      text-align: right;

      @include mobile {
        text-align: left;
      }

      .copyright-title {
        color: white;
        font-weight: normal;
      }
    }
  }
}
