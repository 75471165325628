.confirm-button {
  height: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border-radius: 30px;
  padding: 0 30px;
  font-size: 16px;
  color: #ffffff;
  background-color: map-get($color, green);
  border: 0;
  outline: none;
  transition: ease all 0.25s;

  &:hover {
    cursor: pointer;
    background-color: darken(map-get($color, green), 3%);
  }

  &:active {
    background-color: darken(map-get($color, green), 5%);
  }
}
