@mixin mobile {
  @media (max-width: 767.98px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin tabletOnly {
  @media (min-width: 768px) and (max-width: 991.98px) {
    @content;
  }
}

@mixin toDesktop {
  @media (max-width: 991.98px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 992px) {
    @content;
  }
}
