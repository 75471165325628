.technology {
  padding: 95px 0 100px 0;
  background: url(/static/images/technology-background.jpg) no-repeat;
  background-position: center center;
  background-size: cover;

  @include mobile {
    padding: 60px 0 64px 0;
  }

  .technology-title {
    font-size: 16px;
    font-family: "Manrope", sans-serif;
    font-weight: bold;
    line-height: 1;
    color: white;
  }

  .technology-content {
    margin-top: 50px;
    padding: 55px 40px;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 6px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;

    @include mobile {
      grid-template-columns: 1fr;
      margin-top: 40px;
      padding: 30px 16px 40px 16px;
    }

    .content-intro {
      font-family: "Manrope", sans-serif;
      font-size: 36px;
      font-weight: bold;
      line-height: 48px;
      color: map-get($color, grey);

      @include mobile {
        font-size: 24px;
        line-height: 32px;
      }

      .tecnology-item {
        white-space: nowrap;
        color: white;
        display: inline-grid;
        grid-auto-flow: column;
        grid-gap: 5px;
        align-items: baseline;

        @include mobile {
          grid-gap: 0;
        }

        .svg-icon {
          vertical-align: baseline;
          align-self: center;

          @include mobile {
            transform: scale(0.7);
          }
        }
      }
    }

    .content-text {
      font-family: "Manrope", sans-serif;
      font-size: 24px;
      font-weight: normal;
      line-height: 36px;
      color: white;

      @include mobile {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
