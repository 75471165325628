.nav {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 40px;

  .nav-item {
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    color: map-get($color, dark);
    transition: ease color 0.25s;

    &:hover {
      text-decoration: underline;
      color: map-get($color, green);
    }

    &.is-active {
      color: map-get($color, green);
    }
  }
}
