.copyright {
  font-size: 12px;
  line-height: 20px;

  .copyright-title {
    font-weight: bold;
    color: map-get($color, dark);
  }

  .copyright-text {
    color: map-get($color, grey);
  }
}
