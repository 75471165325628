.expertise {
  margin: 100px 0 50px 0;

  @include mobile {
    margin: 60px 0;
  }

  .expertise-title {
    font-size: 16px;
    font-family: "Manrope", sans-serif;
    font-weight: bold;
    line-height: 1;
  }

  .expertise-items-list {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px 52px;

    @include mobile {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
  }
}
