.form-response {
  display: grid;
  grid-gap: 32px;
  padding-top: 62px;

  @include mobile {
    padding-top: 60px;
  }

  &.response-success {
    .response-header {
      .svg-icon {
        fill: map-get($color, green);
      }
    }
  }

  &.response-error {
    .response-header {
      .svg-icon {
        fill: map-get($color, red);
      }
    }
  }

  .response-header {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-gap: 16px;

    .response-title {
      font-size: 40px;
      font-weight: bold;
      font-family: "Manrope", sans-serif;
      color: #ffffff;
    }
  }

  .response-body {
    font-size: 24px;
    line-height: 36px;
    color: #ffffff;

    a {
      text-decoration: none;
      color: map-get($color, blue);
      font-weight: bold;
    }
  }

  .retry-button {
    justify-self: start;
  }
}
