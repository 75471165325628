.address {
  font-size: 12px;
  font-style: normal;
  line-height: 20px;

  .address-line1 {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    align-items: center;
    grid-gap: 5px;
  }
}
