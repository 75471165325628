html,
body {
  height: 100%;
}

body {
  margin: 0;
  overflow-x: hidden;
  background-color: map-get($color, light);
  font-family: "Open Sans", sans-serif;
  color: map-get($color, dark);
}

body * {
  box-sizing: border-box;
}

.container {
  width: 100%;
  max-width: 1168px;
  margin: 0 auto;
  padding: 0 16px;
}

.svg-icon {
  display: inline-block;
  vertical-align: top;
}

.no-scroll {
  overflow: hidden;
}
